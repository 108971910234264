import React, { FunctionComponent, useState, useEffect } from "react";
import { Form, Input, Button, Select, notification, Tooltip, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styles from "./SessionForm.module.scss";
import TextArea from "antd/lib/input/TextArea";
import { getEnums, getTag } from "app/services/masterlist.service";
import { masterSessionCreation } from "app/services/masterSession.service";
import SelectTenant from "app/container/LmsSession/CreateLmsSession/components/SelectTenant/SelectTenant";
import { reload } from "app/utils/common";
const { Option } = Select;

interface IProp extends FormComponentProps {}

const SessionForm: FunctionComponent<IProp> = ({ form }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [requiredEnum, setRequiredEnum] = useState<any>();
  const [tagEnum, setTagEnum] = useState<any>();
  const [tenantId, setTenantId] = useState<string>();

  /* TODO: it will be used when upload functionality added in API */

  // const [logo, setLogo] = useState<any>();
  // const [image, setImage] = useState<any>();

  const getRequiredEnum = async () => {
    try {
      const res = await getEnums();
      if (res && res.masterSession.requiredFields) {
        // for converting object into array
        setRequiredEnum(Object.values(res.masterSession.requiredFields));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTagEnum = async () => {
    try {
      const res = await getTag();
      if (res && res.data) {
        // for converting object into array
        setTagEnum(Object.values(res.data.rows));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getTagEnum();
    getRequiredEnum();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        setLoading(true);
        try {
          const res = await masterSessionCreation({ ...values, tenantId });
          if (res.data) {
            notification["success"]({
              message: "Session Added Successfully"
            });
          }
          form.resetFields();
          reload(true);
        } catch (_error) {
          notification["error"]({
            message: _error.data ? _error.data.error.message : _error.message
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };
  return (
    <div>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputDisplay}>
          <Alert className={styles.alert} type="warning" showIcon message="If no Tenant is selected, it will be a Generic Session." />
          <SelectTenant width="100%" placeholder="Select tenant" label="Select Tenant" updateSelectChange={value => setTenantId(value)} />
          <Form.Item label="Title">
            {getFieldDecorator("title", {
              rules: [
                {
                  type: "string",
                  message: "Please enter a valid title."
                },
                {
                  required: true,
                  message: "Please enter an title."
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Slug">
            {getFieldDecorator("slug", {
              rules: [
                {
                  type: "string",
                  message: "Please enter a valid Slug."
                },
                {
                  required: true,
                  message: "Please enter an slug."
                }
              ]
            })(<Input />)}
          </Form.Item>
        </div>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid description."
              },
              {
                required: true,
                message: "Please enter product description."
              }
            ]
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label="Icon  ">
          {getFieldDecorator("icon", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid title."
              },
              {
                required: true,
                message: "Please enter a product icom"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Image">
          {getFieldDecorator("image", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid image link"
              },
              {
                required: true,
                message: "Please enter product image link."
              }
            ]
          })(<Input />)}
        </Form.Item>
        {/* TODO: it will be used when upload functionality added in API */}
        {/* <div className={styles.inputDisplay}>
        <Form.Item label='Image'>
          {getFieldDecorator('image', {
            rules: [
              {
                required: true,
                message: 'Please upload an image.',
              },
            ],
          })(
            <Upload
              name='logo'
              beforeUpload={file => {
                setImage([file]);
                return false;
              }}
              accept='.jpg,.png,.jpeg'
              listType='picture'
              fileList={logo}
              onRemove={() => {
                setImage([]);
              }}
            >
              <Button>
                <Icon type='upload' /> Click to upload
              </Button>
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label='Icon'>
          {getFieldDecorator('icon', {
            rules: [
              {
                required: true,
                message: 'Please upload an icon.',
              },
            ],
          })(
            <Upload
              name='logo'
              beforeUpload={file => {
                setLogo([file]);
                return false;
              }}
              accept='.jpg,.png,.jpeg'
              listType='picture'
              fileList={logo}
              onRemove={() => {
                setLogo([]);
              }}
            >
              <Button>
                <Icon type='upload' /> Click to upload
              </Button>
            </Upload>,
          )}
        </Form.Item>
        </div> */}
        <div className={styles.inputDisplay}>
          <Form.Item label="Tags">
            {getFieldDecorator("tags", {
              rules: [
                {
                  required: true,
                  message: "Please select at least one tag"
                }
              ]
            })(
              <Select mode="multiple" placeholder="Please select">
                {tagEnum &&
                  tagEnum.map(({ name }: any, i: number) => (
                    <Option value={name} key={i}>
                      {name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Duration (In Mins)">
            {getFieldDecorator("duration", {
              rules: [
                {
                  required: true,
                  message: "Please provide the duration"
                },
                {
                  pattern: /^(0|[1-9][0-9]*)$/,
                  message: "Please enter duration in numbers values"
                }
              ],
              initialValue: "60"
            })(<Input />)}
          </Form.Item>
          {/* TODO: to add multiple redundant select support */}
          <Form.Item label="Required Fields">
            {getFieldDecorator("requiredFields", {
              rules: [
                {
                  required: true,
                  message: "Please select atleast one required fields"
                }
              ]
            })(
              <Select mode="multiple" placeholder="Please select">
                {requiredEnum &&
                  requiredEnum.map(({ value, description }: any, i: any) => (
                    <Option value={value} key={i}>
                      {description}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          {/* TODO: need to add this once added in the API */}
          {/* <Form.Item label='Enabled'>
            <Switch />
          </Form.Item> */}
          <Form.Item label="Coach Feedback URL">
            {getFieldDecorator("coachFeedbackUrl", {
              rules: [
                {
                  required: true,
                  message: "Please enter coach feedback URl."
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Student Feedback URL">
            {getFieldDecorator("studentFeedbackUrl", {
              rules: [
                {
                  required: true,
                  message: "Please enter student feedback URL."
                }
              ]
            })(<Input />)}
          </Form.Item>
        </div>
        <div className={styles.buttonWrapper}>
          <Tooltip title={!tenantId && "If no Tenant is selected, it will be a Generic Session."}>
            <Button htmlType="submit" loading={loading} type="primary">
              Submit
            </Button>
          </Tooltip>
        </div>
      </Form>
    </div>
  );
};

const SessionFormCreation = Form.create<IProp>({ name: "form" })(SessionForm);
export default SessionFormCreation;
