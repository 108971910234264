import React, { FunctionComponent } from "react";
import MainLayout from "app/layouts/MainLayout";
import SessionCreationForm from "app/container/Session/Form";

const AdminPage: FunctionComponent = () => {
  return (
    <MainLayout title="Create New Session" subtitle="Create a new session">
      <SessionCreationForm />
    </MainLayout>
  );
};

export default AdminPage;
